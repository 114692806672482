import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import { CircularProgress } from '@material-ui/core'

import Page from '../components/Page'
import { colors, fonts } from '../styles/variables'
import { useMst } from '../models/Root'
import PageHeader from '../components/PageHeader'

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px auto;
`

const Text = styled.span`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.black};
`

const VerifyEmail = observer(() => {
  const {
    authStore: { verifyEmail, fetching }
  } = useMst()

  const [success, setSuccess] = useState<boolean | null>(null)

  const { token }: { token?: string } = queryString.parse(location.search)

  useEffect(() => {
    if (token) verifyEmail(token).then(setSuccess)
  }, [token, verifyEmail])

  const renderContent = () => {
    if (fetching) return <CircularProgress />
    if (success) return <Text>Sähköpostiosoite varmistettu! Voit nyt kirjautua uusilla tunnuksillasi sisään.</Text>
    if (success === false) return <Text>Jokin meni pieleen :(</Text>
    return null
  }

  return (
    <Page>
      <PageContainer>
        <PageHeader title={token ? 'Kiitos!' : '🤔'} />
        <Container>{renderContent()}</Container>
      </PageContainer>
    </Page>
  )
})

export default VerifyEmail
